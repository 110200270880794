import React, { useState } from 'react';
import './ProductRegistration.css';
function ProductRegistration() {
    const BE_CREATE_URL = 'https://kevin-api.devops-max.com/api/products'

    const [product, setProduct] = useState({
        name: '',
        quantity: 0,
        price: 0,
        info: '',
    });
    const [file, setFile] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // 파일 상태 업데이트
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('file', file); // 파일 추가
        Object.keys(product).forEach(key => {
            formData.append(key, product[key]); // 다른 상품 정보 추가
        });

        // 백엔드로 formData 전송
        fetch(BE_CREATE_URL, {
            method: 'POST',
            body: formData, // 바디를 formData로 설정
            // 'Content-Type': 'multipart/form-data' 헤더는 자동으로 설정됩니다.
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            alert('상품이 성공적으로 등록되었습니다.');
            // 폼 초기화
            setProduct({
                name: '',
                quantity: 0,
                price: 0,
                info: '',
            });
            setFile(null);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>상품 등록</h2>
            <div>
                <label>상품명:</label>
                <input type="text" name="name" value={product.name} onChange={handleChange} required />
            </div>
            <div>
                <label>수량:</label>
                <input type="number" name="quantity" value={product.quantity} onChange={handleChange} required />
            </div>
            <div>
                <label>가격:</label>
                <input type="number" name="price" value={product.price} onChange={handleChange} required />
            </div>
            <div>
                <label>상품 설명:</label>
                <textarea name="info" value={product.info} onChange={handleChange} required></textarea>
            </div>
            <div>
                <label>이미지 파일:</label>
                <input type="file" onChange={handleFileChange} required />
            </div>
            <button type="submit">상품 등록</button>
        </form>
    );
}

export default ProductRegistration;
