import React from 'react';
import './App.css';
import ProductRegistration from './ProductRegistration';

function Navbar() {
  return (
    <div className="navbar">
      <h1>Kevin Life Care Admin Page</h1>
    </div>
  );
}

function CategoryBar() {
  return (
    <div className="category-bar">
      <h2>Admin</h2>
      <ul>
        <li>상품 등록</li>
      </ul>
    </div>
  );
}

function App() {

  return (
    <div>
      <Navbar />
      <div className="content">
        <CategoryBar />
        <div className="main-content">
        <ProductRegistration /> {/* 상품 등록 컴포넌트 렌더링 */}
        </div>
      </div>
    </div>
  );
}

export default App;
